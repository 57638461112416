import * as ko from 'knockout';
import { API_CONFIG } from '../config';
import { createIncidentField } from '../interface/IncidentField';
import { getQueryParams } from '../utils';
class FormViewModel {
    // Constructor with dependency injection of MainViewModel
    constructor(mainViewModel) {
        this.mainViewModel = mainViewModel;
        // Observables for Step 2
        this.buildings = ko.observableArray([]);
        this.contracts = ko.observableArray([]);
        this.selectedBuilding = ko.observable();
        this.selectedContract = ko.observable();
        this.selectedBuildingLabel = ko.observable("");
        this.selectedContractLabel = ko.observable("");
        this.externalId = ko.observable("");
        this.tenantId = ko.observable("");
        this.incidentCategories = ko.observableArray([]);
        this.selectedLevel1Category = ko.observable(null);
        this.selectedLevel2Category = ko.observable(null);
        this.selectedLevel3Category = ko.observable(null);
        this.level2Categories = ko.observableArray([]);
        this.level3Categories = ko.observableArray([]);
        this.incidentFields = ko.observableArray([]);
        this.requestMaker = ko.observable(null); // Storing the requestMaker object
        this.isSubmitting = ko.observable(false); // Flag to track submission state
        this.isSubmittedSuccessfully = ko.observable(false); // Flag to track successful submission
        this.isLoadingBuildings = ko.observable(false); // Flag to track loading state of buildings
        this.dropdownVisible = ko.observable(false); // Control dropdown visibility
        this.buildingSearchQuery = ko.observable(""); // New observable for search input
        // Debounce mechanism for autocomplete to prevent too many API calls
        this.buildingSearchQueryDelayed = ko.pureComputed(this.buildingSearchQuery)
            .extend({ rateLimit: { method: "notifyWhenChangesStop", timeout: 300 } });
        // Array of key-value pairs for URL parameters
        this.urlParams = ko.observableArray([
            { urlkey: 'Versicherungsnummer', observableName: 'selectedContractLabel' },
            { urlkey: 'Vorgangsnummer', observableName: 'externalId' }
        ]);
        // Computed observable to filter and format buildings with bold matching letters
        this.filteredBuildings = ko.computed(() => {
            const query = this.buildingSearchQuery().toLowerCase();
            return this.buildings().map(building => {
                const address = building.address || ""; // Ensure address is a string
                let formattedAddress = address;
                if (query && address.toLowerCase().includes(query)) {
                    const startIndex = address.toLowerCase().indexOf(query);
                    const endIndex = startIndex + query.length;
                    formattedAddress = `${address.slice(0, startIndex)}<strong>${address.slice(startIndex, endIndex)}</strong>${address.slice(endIndex)}`;
                }
                return Object.assign(Object.assign({}, building), { formattedAddress: formattedAddress });
            });
        });
        this.selectBuilding = (building) => {
            console.log('Selected building:', building);
            this.selectedBuilding(building.id);
            this.buildingSearchQuery(building.address); // Set the search query to the selected address
            this.dropdownVisible(false); // Hide the dropdown after selection
        };
        this.showDropdown = () => {
            this.dropdownVisible(true);
        };
        this.hideDropdown = () => {
            this.dropdownVisible(false);
        };
        this.hideDropdownDelayed = () => {
            setTimeout(() => {
                this.dropdownVisible(false);
            }, 200); // Delay to allow for click event to register
        };
        this.updateLevel2Categories = (incidentCategoryId) => {
            // Find the category object that matches the selected ID
            const selectedCategory = this.incidentCategories().find(cat => cat.id === incidentCategoryId);
            if (selectedCategory && selectedCategory.children) {
                this.level2Categories(selectedCategory.children);
                this.level3Categories([]); // Reset level 3 categories
                this.selectedLevel2Category(null); // Reset level 2 selected category
            }
            else {
                this.level2Categories([]);
                this.level3Categories([]);
            }
        };
        this.updateLevel3Categories = () => {
            var _a, _b;
            // Find the category object that matches the selected ID
            const selectedCategory = (_b = (_a = this.incidentCategories().find(x => x.id === this.selectedLevel1Category())) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b.find(x => x.id === this.selectedLevel2Category());
            if (selectedCategory && selectedCategory.children) {
                this.level3Categories(selectedCategory.children);
            }
            else {
                this.level3Categories([]);
            }
        };
        this.onBuildingSelected = (selectedBuildingId) => {
            if (selectedBuildingId === null) {
                this.selectedBuildingLabel('');
                return;
            }
            // Find the selected building and update its label
            const building = this.buildings().find(b => b.id === selectedBuildingId);
            if (building) {
                this.selectedBuildingLabel(building.address);
            }
        };
        this.onContractSelected = (selectedContractId) => {
            if (selectedContractId === null) {
                this.selectedContractLabel('');
                return;
            }
            const contract = this.contracts().find(c => c.id === selectedContractId);
            if (contract) {
                this.selectedContractLabel(contract.name);
            }
        };
        this.conditionallySendForm = () => {
            const categoryPath = this.constructCategoryPath();
            const formData = {
                buildingId: this.selectedBuilding(),
                contractId: this.selectedContract(),
                categoryPath: categoryPath,
                externalId: this.externalId(),
                tenantId: this.tenantId()
            };
            // Process requestMaker data
            const processedRequestMaker = this.processRequestMakerData(this.requestMaker());
            // Combine formData and processedRequestMaker
            const submissionData = Object.assign(Object.assign({}, formData), processedRequestMaker);
            console.log(submissionData);
            if (API_CONFIG.debugMode) {
                // Mock the API response when in debug mode
                return Promise.resolve({
                    json: () => Promise.resolve({
                        message: 'Mocked submission successful',
                        mockData: submissionData // Optionally include the mocked data for debugging purposes
                    })
                });
            }
            // Retrieve the accessToken from local storage
            const accessToken = JSON.parse(localStorage.getItem('accessToken') || '');
            // Actual API call when not in debug mode
            return this.fetchWithAuthorization(`${API_CONFIG.baseUrl}${API_CONFIG.endpoints.submitForm}?category=${categoryPath}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-provider': 'idwell',
                    'x-tenant-id': 'incon',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(submissionData)
            });
        };
        this.submitForm = () => {
            if (this.isSubmitting()) {
                return; // Prevent multiple submissions
            }
            this.isSubmitting(true); // Set submitting flag to true
            this.conditionallySendForm()
                .then(response => response.json())
                .then(data => {
                console.log('Submission successful', data);
                // Handle successful submission (e.g., navigate to a confirmation page or show a success message)
            })
                .catch(error => {
                console.error('Submission failed', error);
                // Handle errors (e.g., show an error message)
            })
                .finally(() => {
                this.isSubmitting(false); // Reset submitting flag
                this.isSubmittedSuccessfully(true);
            });
        };
        this.mainViewModel.isUserAuthenticated.subscribe((authenticated) => {
            if (authenticated) {
                // Initialize data for buildings  
                this.fetchBuildings();
                // Fetch incident categories
                this.fetchIncidentCategories();
                // Subscription to handle the autocomplete search behavior
                this.buildingSearchQueryDelayed.subscribe(query => {
                    if (query.length >= 3) {
                        this.fetchBuildings(query); // Fetch buildings with address filter when query is 3 characters or more
                    }
                    else if (query.length === 0) {
                        this.fetchBuildings(); // Fetch all buildings if the input is cleared
                    }
                    else {
                        this.buildings([]); // Clear the listbox if input is less than 3 characters (but not empty)
                    }
                });
            }
        });
        // Subscribe to changes in selectedBuilding to update contracts
        this.selectedBuilding.subscribe(this.onBuildingSelected);
        this.selectedBuilding.subscribe(this.fetchContracts.bind(this));
        this.selectedContract.subscribe(this.onContractSelected);
        this.selectedLevel1Category.subscribe(this.updateLevel2Categories);
        this.selectedLevel2Category.subscribe(this.updateLevel3Categories);
        // Fetch external ID from query parameters and populate the observable
        const params = getQueryParams();
        if (params['externalid']) {
            this.externalId(params['externalid']);
        }
        // Fetch tenant ID from query parameters and populate the observable
        if (params['tenantid']) {
            this.tenantId(params['tenantid']);
        }
        // Add computed observable to check if the form for Step 2 is valid
        this.isStep2FormValid = ko.computed(() => {
            return this.selectedBuilding() &&
                this.selectedContract() &&
                this.selectedLevel1Category() &&
                this.externalId().trim() !== "";
        });
        // Subscribe to changes in observables to update the URL
        this.urlParams().forEach(param => {
            const observable = this[param.observableName];
            if (ko.isObservable(observable)) {
                observable.subscribe((newValue) => {
                    this.updateUrlParam(param.urlkey, newValue);
                });
            }
        });
    }
    // Function to fetch buildings, optionally with a search query for the address
    fetchBuildings(searchQuery) {
        this.isLoadingBuildings(true); // Set loading flag to true
        let url = `${API_CONFIG.baseUrl}${API_CONFIG.endpoints.buildings}`;
        if (searchQuery) {
            url += `?address=${encodeURIComponent(searchQuery)}`;
        }
        this.fetchWithAuthorization(url)
            .then(response => response.json())
            .then(data => this.buildings(data)) // Assuming data is an array of buildings
            .catch((error) => {
            console.error('Error fetching buildings:', error);
        })
            .finally(() => this.isLoadingBuildings(false)); // Reset loading state
    }
    fetchContracts() {
        if (!this.selectedBuilding())
            return;
        this.fetchWithAuthorization(`${API_CONFIG.baseUrl}${API_CONFIG.endpoints.contracts}?building=${this.selectedBuilding()}`)
            .then(response => response.json())
            .then(data => this.contracts(data))
            .catch(error => console.error('Error fetching contracts:', error));
    }
    fetchIncidentCategories() {
        this.fetchWithAuthorization(`${API_CONFIG.baseUrl}${API_CONFIG.endpoints.incidentCategories}`)
            .then(response => response.json())
            .then(data => this.incidentCategories(data))
            .catch(error => console.error('Error fetching incident categories:', error));
    }
    fetchIncidentFields() {
        const categoryPath = this.constructCategoryPath();
        // Fetch incident fields based on the selected category path
        this.fetchAsApiUser(`${API_CONFIG.baseUrl}${API_CONFIG.endpoints.incidentFields}?category=${categoryPath}`)
            .then(response => response.json())
            .then(data => {
            const fields = data.questions.map(createIncidentField); // Convert each field in the response to an IncidentField object
            this.incidentFields(fields);
            this.requestMaker(data.requestMaker); // Store the requestMaker
        })
            .catch(error => console.error('Error fetching incident fields:', error));
    }
    constructCategoryPath() {
        // Construct the path based on selected categories
        let path = '';
        if (this.selectedLevel1Category()) {
            path += this.selectedLevel1Category();
            if (this.selectedLevel2Category()) {
                path += `.${this.selectedLevel2Category()}`;
                if (this.selectedLevel3Category()) {
                    path += `.${this.selectedLevel3Category()}`;
                }
            }
        }
        return path;
    }
    processRequestMakerData(requestMakerData) {
        let title = requestMakerData.title;
        let text = requestMakerData.text;
        // Replace special parameters
        title = title.replace("{{building_address}}", this.selectedBuildingLabel());
        text = text.replace("{{building_address}}", this.selectedBuildingLabel());
        title = title.replace("{{flat_number}}", this.selectedContractLabel());
        text = text.replace("{{flat_number}}", this.selectedContractLabel());
        // Replace other placeholders with field values
        this.incidentFields().forEach(field => {
            const fieldValue = field.value();
            title = title.replace(`{{${field.name}}}`, fieldValue);
            text = text.replace(`{{${field.name}}}`, fieldValue);
        });
        return {
            title: title,
            text: text,
            status: requestMakerData.status,
            category: requestMakerData.category
        };
    }
    fetchAsApiUser(url) {
        // Retrieve the accessToken from local storage
        const accessToken = JSON.parse(localStorage.getItem('accessToken') || '');
        if (!accessToken) {
            return Promise.reject('Kein Access Token vorhanden. Anmeldung erforderlich');
        }
        // Make the request with the access token
        return fetch(`${url}`, {
            method: 'GET',
            headers: {
                'x-api-provider': 'idwell',
                'Authorization': `Bearer ${accessToken}`
            }
        });
    }
    // Centralized function to handle fetch calls and 401 responses
    fetchWithAuthorization(url, options = {}) {
        const accessToken = JSON.parse(localStorage.getItem('accessToken') || '');
        if (!accessToken) {
            this.handleUnauthorized();
            return Promise.reject('Kein Access Token vorhanden.');
        }
        // Add Authorization header
        options.headers = Object.assign(Object.assign({}, options.headers), { 'Authorization': `Bearer ${accessToken}`, 'x-api-provider': 'idwell' });
        return fetch(url, options).then(response => {
            if (response.status === 401) {
                this.handleUnauthorized();
                return Promise.reject('Unauthorized');
            }
            return response;
        }).catch(error => {
            console.error('Error:', error);
            throw error; // Re-throw the error after logging it
        });
    }
    handleUnauthorized() {
        // Clear user authentication and token
        localStorage.removeItem('accessToken');
        this.mainViewModel.isUserAuthenticated(false);
        // Show notification and redirect to login
        this.mainViewModel.notification("Sitzung veraltet. Anmeldung erforderlich.");
        this.mainViewModel.goToStep1(); // Redirect to login page
    }
    updateUrlParam(key, value) {
        // Update the URL with the new parameter value
        const url = new URL(window.location.href);
        if (value) {
            url.searchParams.set(key, encodeURIComponent(value));
        }
        else {
            url.searchParams.delete(key); // Remove the param if the value is empty or null
        }
        window.history.replaceState({}, '', url); // Update the URL without reloading the page
    }
}
// Handling clicks outside the dropdown
document.addEventListener('click', function (event) {
    const inputElement = document.querySelector('#buildingSearch');
    if (inputElement) {
        const container = inputElement.closest('.relative');
        if (container && !container.contains(event.target)) {
            const viewModel = ko.dataFor(container);
            if (viewModel && viewModel.dropdownVisible) {
                viewModel.dropdownVisible(false);
            }
        }
    }
});
export default FormViewModel;
