export const API_CONFIG = {
    baseUrl: 'https://domoforce-claimsform.azurewebsites.net',
    //baseUrl: 'http://localhost:3000',
    endpoints: {
        auth: '/auth',
        buildings: '/buildings',
        contracts: '/contracts',
        incidentCategories: '/incident-categories',
        incidentFields: '/incident-fields',
        submitForm: '/incident'
    },
    debugMode: false
};
