export function getQueryParams() {
    const params = {};
    const queryString = window.location.search.substring(1);
    const queryParts = queryString.split('&');
    queryParts.forEach(part => {
        const [key, value] = part.split('=');
        if (key && value) {
            params[key.toLowerCase()] = decodeURIComponent(value);
        }
    });
    return params;
}
