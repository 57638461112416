// IncidentField.ts
import * as ko from 'knockout';
import { getQueryParams } from '../utils';
export function createIncidentField(data) {
    const params = getQueryParams();
    let defaultValue = '';
    // Check for specific suffix and assign the default value if a query parameter exists
    for (const [key, value] of Object.entries(params)) {
        if (data.name.toLowerCase().endsWith(key)) {
            defaultValue = value;
            break;
        }
    }
    // Map your API response to the IncidentField structure
    return {
        label: data.label,
        name: data.name,
        type: data.type,
        value: ko.observable(defaultValue) // Initialize with default value if applicable
    };
}
