import * as ko from 'knockout';
import LoginFormViewModel from './LoginFormViewModel';
import FormViewModel from './FormViewModel';
class MainViewModel {
    constructor() {
        this.isUserAuthenticated = ko.observable(false);
        this.formViewModel = new FormViewModel(this);
        this.showLoginForm = ko.observable(true);
        this.showFormStep2 = ko.observable(false);
        this.showFormStep3 = ko.observable(false); // Assuming you have a Form Step 3
        // ... other form steps visibility observables ...
        this.notification = ko.observable(null); // Add notification observable
        this.goToStep1 = () => {
            this.showLoginForm(true);
            this.showFormStep2(false);
            this.showFormStep3(false);
            // ... hide other form steps ...
        };
        this.goToStep2 = () => {
            this.showLoginForm(false);
            this.showFormStep2(true);
            this.showFormStep3(false);
            // ... hide other form steps ...
        };
        this.goToStep3 = () => {
            this.showLoginForm(false);
            this.showFormStep2(false);
            this.showFormStep3(true);
            // ... hide other form steps ...
            // Call fetchIncidentFields when transitioning to Step 3
            this.formViewModel.fetchIncidentFields();
        };
        // When creating LoginFormViewModel, pass a callback that sets isUserAuthenticated
        this.loginFormViewModel = new LoginFormViewModel(() => {
            this.isUserAuthenticated(true);
            this.notification(''); // Clear any previous notifications
            this.goToStep2();
        }, this);
        // Check if accessToken is already in localStorage
        const token = localStorage.getItem('accessToken');
        if (token) {
            // Simulate successful login if token is found
            this.isUserAuthenticated(true);
            this.goToStep2();
        }
    }
}
export default MainViewModel;
