import * as ko from 'knockout';
import { API_CONFIG } from '../config';
class LoginFormViewModel {
    constructor(onLoginSuccess, mainViewModel) {
        this.onLoginSuccess = onLoginSuccess;
        this.mainViewModel = mainViewModel;
        this.licenseKey = ko.observable('');
        this.inconUser = ko.observable('');
        this.inconPassword = ko.observable('');
        this.isSubmitting = ko.observable(false); // Flag to track submission state
        this.isFormValid = ko.computed(() => {
            return this.licenseKey() && this.inconUser() && this.inconPassword();
        });
        this.submitForm = () => {
            if (!this.isFormValid() || this.isSubmitting()) {
                return; // Prevent submission if form is not valid or already submitting
            }
            this.isSubmitting(true); // Set submitting flag to true
            // accessToken is added to Authorization header if local storage has a accessToken
            fetch(`${API_CONFIG.baseUrl}${API_CONFIG.endpoints.auth}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-provider': 'idwell'
                },
                body: JSON.stringify({
                    password: this.licenseKey(),
                    apiUser: this.inconUser(),
                    apiPassword: this.inconPassword()
                })
            })
                .then(response => {
                if (!response.ok) {
                    // Check if the response is 401 and handle it
                    if (response.status === 401) {
                        return response.text().then((text) => {
                            throw new Error(text); // Throw the error with the server's message
                        });
                    }
                    throw new Error(`Fehler ${response.status}: ${response.statusText}`);
                }
                return response.json(); // Only attempt to parse JSON if the response was successful
            })
                .then(data => {
                // Handle response here
                console.log('Success:', data);
                // Store the accessToken in local storage
                localStorage.setItem('accessToken', JSON.stringify(data.access_token));
                // On successful login, call the callback
                this.onLoginSuccess();
            })
                .catch((error) => {
                console.error('Error:', error);
                this.mainViewModel.notification(error.message);
            })
                .finally(() => {
                this.isSubmitting(false); // Reset submitting flag
            });
        };
    }
}
export default LoginFormViewModel;
